.chatbot-container {
  width: 800px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 10;
  right: 15px;
  bottom: 15px;
}
.chatbot-messages {
  height: 400px;
  overflow-y: scroll;
  padding: 8px;
}
.message {
  margin: 8px 0;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
}
.user-message {
  background-color: #007bff;
  color: #fff;
  text-align: right;
}
.ai-message {
  background-color: #f0f0f0;
  text-align: left;
}
.chatbot-input-form {
  display: flex;
  margin-top: 16px;
}
.chatbot-input-form input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  font-size: 16px;
}
.chatbot-input-form button {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 0 4px 4px 0;
  font-size: 16px;
  cursor: pointer;
}
.chatbot-input-form button:hover {
  background-color: #0056b3;
}

.chatbot-questions-container {
  position: fixed;
  z-index: 10;
  right: 15px;
  bottom: 150px;
}

.chatbot-questions{
  float: right;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px;
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  margin-top: 20px;
  min-width: none;
  clear: both;
}
