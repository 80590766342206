.tblHeaderStyle {
    background-color: #333f50;
    color: #FFFFFF;
    font-size: 12px;    
    vertical-align: middle !important;
    border: #FFFFFF 1px solid;
}

.tblRowStyle {
    background-color: #e9ebf5;
    color: #000000;
    font-size: 12px;    
    vertical-align: middle !important;
    border: #FFFFFF 1px solid;
}
