/* Provide sufficient contrast against white background */

a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff !important;
    background-color: #1b6ec2 !important;
    border-color: #1861ac !important;
}

.btn-warning-edit {
    height: 26px !important;
    font-size: 10px !important;
    font-family: IBMPlexSans;
    background-color: #f5b025 !important;
    color: #fff !important;
}

.btn-warning {
    font-family: IBMPlexSans;
    background-color: #f5b025 !important;
    color: #fff !important;
}

.btn-white {
    background-color: #ffffff !important;
    border-color: #ced4da !important;
    color: #212529 !important;
}

.btn-resume {
    color: #fff !important;
    background-color: #ed7d31 !important;
    border-color: #ed7d31 !important;
}

.btn-resume:hover {
    color: #fff !important;
    background-color: #ed7d31 !important;
    border-color: #ed7d31 !important;
}

.color-dark {
    color: #2b2b2b;
}

.color-primary {
    color: #4279e6;
}

.color-secondary {
    color: #e6e6e6;
}

@font-face {
    font-family: "IBMPlexSans-Bold";
    src: local("IBMPlexSans-Bold"), url(./fonts/IBMPlexSans-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "IBMPlexSans-Light";
    src: local("IBMPlexSans-Light"), url(./fonts/IBMPlexSans-Light.ttf) format("truetype");
}

@font-face {
    font-family: "IBMPlexSans";
    src: local("IBMPlexSans"), url(./fonts/IBMPlexSans-Regular.ttf) format("truetype");
}

/****** main content *********/
.btnPrimary-Style {
    height: 40px;
    font-size: 10px;
    font-family: IBMPlexSans-Bold;
    background-color: #6699ff;
}

.btnSuccess-Style {
    height: 40px;
    font-size: 10px !important;
    font-family: IBMPlexSans-Bold;
    background-color: #92d050 !important;
}

.btnDanger-Style {
    height: 40px;
    font-size: 10px !important;
    font-family: IBMPlexSans-Bold;
    background-color: #ff0000 !important;
}

.btnSecondary-Style {
    height: 40px;
    font-size: 10px !important;
    font-family: IBMPlexSans-Bold;
    background-color: #e6e6e6 !important;
}

.btmMentor-Style{
    height: 40px;
    font-size: 10px !important;
    font-family: IBMPlexSans-Bold;
    background-color: #8ed973 !important;    
}

.btnPropose-Style{
    font-size: 12px !important;
    font-family: IBMPlexSans-Bold;
    background-color: #f2aa84 !important;    
}

.btnDisabled-Style {
    height: 40px;
    font-size: 10px;
    font-family: IBMPlexSans-Bold;
    background-color: #e2e6ea !important;
    border-color: #e2e6ea !important;
}

.btnAmber-Style {
    height: 40px;
    font-size: 10px;
    font-family: IBMPlexSans-Bold;
    background-color: #ffc000 !important;
    border-color: #ffc000 !important;
}

.btnEdit-Style {
    height: 26px !important;
    font-size: 10px !important;
    font-family: IBMPlexSans;
    background-color: #e6e6e6 !important;
}

.contentMain {
    width: 974px !important;
    padding: 0 !important;
}

.contentMainMax {
    width: 95% !important;
    padding: 0 !important;
    max-width: 100% !important;
}

.containerMax {
    width: 100%;
    max-width: 100% !important;
}

.contentWrapper-Style {
    border: 1px;
    width: 810px !important;
    height: 100%;
    background-color: #ffffff;
    border-radius: 20px;
    margin-bottom: 10px;
    padding-left: 82px !important;
    padding-right: 82px !important;
    padding-top: 58px;
    padding-bottom: 52px;
}

.contentWrapperLgAdmin-Style {
    border: 1px;
    width: 100% !important;
    /* height: 100%; */
    background-color: #ffffff;
    border-radius: 10px !important;
    margin-bottom: 10px !important;
    padding: 30px !important;
}

.contentWrapperLgAdmin-Style-Sticky {
    border: 1px;
    width: 100% !important;
    /* height: 100%; */
    background-color: #ffffff;
    border-radius: 10px !important;
    margin-bottom: 10px !important;
    padding: 10px 30px !important;
}

.contentWrapperLg-Style {
    border: 1px;
    width: 974px !important;
    /* height: 100%; */
    background-color: #ffffff;
    border-radius: 10px !important;
    margin-bottom: 10px !important;
    padding: 30px !important;
}

.contentWrapperLg-Style-Sticky {
    border: 1px;
    width: 974px !important;
    /* height: 100%; */
    background-color: #ffffff;
    border-radius: 10px !important;
    padding: 10px 30px !important;
}

.contentWrapperLgTransparent-Style {
    width: 974px !important;
    /* height: 100%; */
    margin-bottom: 10px;
    padding: 30px;
}

.contentWrapperLgMaxTransparent-Style {
    width: 100% !important;
    max-width: 100% !important;
    /* height: 100%; */
    margin-bottom: 10px;
    padding: 30px;
}

.custNavSecondActive {
    background: #3164c9;
}

.line {
    width: 100%;
    height: 10px;
    border-top: 1px solid black;
}

.opacityElement {
    filter: alpha(opacity=50);
    opacity: 0.5;
}

.page-title {
    color: #2b2b2b;
    font-family: IBMPlexSans-Bold;
    font-size: 22px;
}

.progress {
    height: 5px !important; /* needs to be an absolute measurement unless one of it's parent has an absolute height */
}

.stepCircle {
    width: 43px;
    height: 43px;
    border: 1px;
}

.stepCircle-small {
    width: 30px;
    height: 30px;
    border: 1px;
}

.stepCircle-active {
    background: #6699ff;
}

.stepCircle-unactive {
    background: #cccccc;
    opacity: 0.25;
}

.stepCircleDark-active {
    background: #3164c9;
}

.stepCircleContent {
    color: #2b2b2b;
    font-family: IBMPlexSans-Bold;
    font-size: 20px;
}

.stepCircleContent-active {
    color: #ffffff;
    font-family: IBMPlexSans-Bold;
    font-size: 20px;
}

/****** Table Element *********/
.circleMark {
    height: 13px;
    width: 13px;
    background: #dbdbdb;
}

.circleMark-active {
    height: 13px;
    width: 13px;
    background: #74e2b1;
}

.circleMark-green {
    height: 13px;
    width: 13px;
    background: #74e2b1;
}

.circleMark-red {
    height: 13px;
    width: 13px;
    background: #ff0000;
}

.circleMark-amber {
    height: 13px;
    width: 13px;
    background: #ffc000;
}

.circleMark-purple {
    height: 13px;
    width: 13px;
    background: #800080;
}

.col-change-fully {
    background: #cc96cc;
}

.col-change-largely {
    background: repeating-linear-gradient(45deg, #cc96cc, #cc96cc 5px, #e5c9e5 5px, #e5c9e5 10px);
}

.col-change-previous {
    background: repeating-linear-gradient(#cc96cc, #cc96cc 5px, #e5c9e5 5px, #e5c9e5 10px);
}

.col-change-unmached {
    background: #e5c9e5;
}

.col-legend-largely {
    background: repeating-linear-gradient(45deg, #707070, #707070 5px, #e6e6e6 5px, #e6e6e6 10px);
}

.col-legend-previous {
    background: repeating-linear-gradient(#707070, #707070 5px, #e6e6e6 5px, #e6e6e6 10px);
}

.col-legend-desirable {
    background: repeating-linear-gradient(90deg, #707070, #707070 5px, #e6e6e6 5px, #e6e6e6 10px);
}

.col-legend-desirable-current {
    background: repeating-linear-gradient(90deg, #488ac7, #488ac7 5px, #d1e2f1 5px, #d1e2f1 10px);
}

.col-legend-desirable-future {
    background: repeating-linear-gradient(90deg, #6cbb3c, #6cbb3c 5px, #daeece 5px, #daeece 10px);
}

.col-respon-fully {
    background: #69849c;
}

.col-respon-required-current {
    background: #488ac7;
}

.col-respon-required-future {
    background: #6cbb3c;
}

.col-respon-unmached {
    background: #ecf0f5;
}

.col-strategy-fully {
    background: #ff9696;
}

.col-strategy-largely {
    background: repeating-linear-gradient(45deg, #ff9696, #ff9696 5px, #ffe3e3 5px, #ffe3e3 10px);
}

.col-strategy-previous {
    background: repeating-linear-gradient(#ff9696, #ff9696 5px, #ffe3e3 5px, #ffe3e3 10px);
}

.col-strategy-unmached {
    background: #ffe3e3;
}

.col-development-fully {
    background: #ffe699;
}

.col-development-largely {
    background: repeating-linear-gradient(45deg, #ffe699, #ffe699 5px, #fff9e6 5px, #fff9e6 10px);
}

.col-development-previous {
    background: repeating-linear-gradient(#ffe699, #ffe699 5px, #fff9e6 5px, #fff9e6 10px);
}

.col-development-unmached {
    background: #fff9e6;
}

.col-delivery-fully {
    background: #ffcc96;
}

.col-delivery-largely {
    background: repeating-linear-gradient(45deg, #ffcc96, #ffcc96 5px, #fff1e3 5px, #fff1e3 10px);
}

.col-delivery-previous {
    background: repeating-linear-gradient(#ffcc96, #ffcc96 5px, #fff1e3 5px, #fff1e3 10px);
}

.col-delivery-unmached {
    background: #fff1e3;
}

.col-relationship-fully {
    background: #abffd1;
}

.col-relationship-largely {
    background: repeating-linear-gradient(45deg, #abffd1, #abffd1 5px, #e2fff0 5px, #e2fff0 10px);
}

.col-relationship-previous {
    background: repeating-linear-gradient(#abffd1, #abffd1 5px, #e2fff0 5px, #e2fff0 10px);
}

.col-relationship-unmached {
    background: #e2fff0;
}

.col-skills-fully {
    background: #b7ecfd;
}

.col-skills-largely {
    background: repeating-linear-gradient(45deg, #b7ecfd, #b7ecfd 5px, #e9f9fe 5px, #e9f9fe 10px);
}

.col-skills-previous {
    background: repeating-linear-gradient(#b7ecfd, #b7ecfd 5px, #e9f9fe 5px, #e9f9fe 10px);
}

.col-skills-unmached {
    background: #e9f9fe;
}

.col-other-fully {
    background: #edc915;
}

.col-other-largely {
    background: repeating-linear-gradient(45deg, #edc915, #edc915 5px, #fff9e6 5px, #fff9e6 10px);
}

.col-other-previous {
    background: repeating-linear-gradient(#edc915, #edc915 5px, #fff9e6 5px, #fff9e6 10px);
}

.col-other-unmached {
    background: #fff9e6;
}

.tableHeader {
    font-family: IBMPlexSans-Bold;
    white-space: nowrap;
    border-top: none;
    color: #ffffff;
}

.td-item {
    border-bottom: solid 1px #e6e6e6;
}

.td-item-respon {
    border-bottom: solid 1px #e6e6e6;
    background-color: #69849c;
    font-family: IBMPlexSans-Bold;
    white-space: nowrap;
    color: #ffffff;
}

.td-value {
    border-bottom: solid 1px #ffffff;
}

.td-fixwidth-170 {
    width: 170px;
}

.td-fixwidth-120 {
    width: 120px;
}

.td-fixwidth-50 {
    width: 50px !important;
}

.td-fixwidth-60 {
    width: 60px !important;
}

.td-fixwidth-70 {
    width: 70px !important;
}

.td-fixwidth-80 {
    width: 80px !important;
}

.td-fixwidth {
    width: 88px;
}

.td-fixwidth-85 {
    width: 85px;
}

.td-fixwidth-39 {
    width: 39px;
}

.th-end {
    border-top-right-radius: 10px;
    border-bottom: 0;
    border-top: 0;
}

.th-start {
    border-top-left-radius: 10px;
    border-bottom: 0;
    border-top: 0;
}

.th-fixwidth-170 {
    width: 170px;
}

.th-fixwidth-120 {
    width: 120px;
}

.th-fixwidth-50 {
    width: 50px;
}

.th-fixwidth-75 {
    width: 75px !important;
    min-width: 75px !important;
}

.th-fixwidth {
    width: 88px !important;
    min-width: 88px !important;
}

.th-fixwidth-extra {
    width: 88px;
    padding-left: 2px !important;
    padding-right: 2px !important;
}

.th-change {
    background-color: #ce559f;
    border-bottom: 0;
    border-top: 0;
}

.th-responsibility {
    background-color: #69849c;
    border-bottom: 0;
    border-top: 0;
}

.th-strategy {
    background-color: #ee2a53;
    border-bottom: 0;
    border-top: 0;
}

.th-development {
    background-color: #f3c716;
    border-bottom: 0;
    border-top: 0;
}

.th-delivery {
    background-color: #b26720;
    border-bottom: 0;
    border-top: 0;
}

.th-relationship {
    background-color: #00a752;
    border-bottom: 0;
    border-top: 0;
}

.th-skills {
    background-color: #00a5e4;
    border-bottom: 0;
    border-top: 0;
}

.th-other {
    background-color: #edc915;
    border-bottom: 0;
    border-top: 0;
}

.tr-hide {
    display: none;
}

.tr-show {
    /*display: block*/
}

.td-edit-skill-header {
    color: #fff;
    font-weight: bold;
    font-size: 80%;
    padding: 5px;
    border-bottom: 1px solid #ffff;
}

.td-edit-skill-subCategory-header {
    font-weight: bold;
    font-size: 80%;
    padding: 5px;
    border-bottom: 1px solid #ffff;
}

.td-edit-skill {
    font-size: 80%;
    padding: 5px;
    border-bottom: 1px solid #ffff;
    border-right: 1px solid #ffff;
}

.td-edit-skill-end {
    font-size: 80%;
    padding: 5px;
    border-bottom: 1px solid #ffff;
}

.td-edit-profile-skill {
    font-size: 80%;
    padding: 5px;
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
}

.td-edit-skill-comment {
    font-size: 70%;
    padding: 5px;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #ffff;
}

.responsibility {
    background-color: #69849c;
}

.form-check-input {
    position: relative !important;
    margin-left: 0px !important;
}

.form-check {
    position: relative !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
}

.label-warning {
    padding: 0.3em 0.7em 0.4em;
    font-size: 80%;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    background-color: #f6bb42;
}

.label-btn-warning {
    padding: 9px !important;
    font-size: 80% !important;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    background-color: #f6bb42;
}

.label-btn-postponed {
    padding: 5px 9px !important;
    font-size: 60% !important;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    background-color: #c82333;
}

.alert-full {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    border-radius: 0px;
}

.alert-fixed {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 300px;
    z-index: 9999;
    border-radius: 0px;
}

.btn-add-other-skill {
    padding-left: 0px !important;
}

.top-right-0-2-2 {
    top: 20px !important;
    right: 20px !important;
}

.msgContainer-0-2-25 {
    padding-right: 10px;
}

.fadeOut {
    opacity: 0;
    transition: width 5s 5s, height 5s 5s, opacity 5s;
}
.fadeIn {
    opacity: 1;
}

.react-bootstrap-table > table > thead > tr > th .filter-label {
    width: 100%;
}

.react-bootstrap-table > table > thead > tr {
    vertical-align: top;
}

.react-bootstrap-table > table > thead > tr > th {
    vertical-align: top;
}

@media (min-width: 476px) and (max-width: 576px){
    .container, .container-sm {
        max-width: 460px;
    }

    .contentWrapper-Style{
        padding: 20px;
    }
}

@media (min-width: 376px) and (max-width: 476px){
    .container, .container-sm {
        max-width: 360px;
    }

    .contentWrapper-Style{
        padding: 20px;
    }
}

@media (min-width: 276px) and (max-width: 376px){
    .container, .container-sm {
        max-width: 260px;
    }

    .contentWrapper-Style{
        padding: 20px;
    }
}

.footerWrapperStyle{
    height: 180px;
    margin-top: 114px;
}

.footerStyle{
    background-color: #2E2E2E;
    font-size: 20px;
    color: #FFFFFF;
    border-top: 1px solid #E7E7E7;
    text-align:center;
    padding: 0px;
    position: absolute;
    height: 180px;
    width: 100%;
    opacity: 0.82;
}

.footerLeftStyle{
    margin-left: 50px;
    margin-top: 10px;
    margin-bottom: 32px;
    float: left;
    text-align: left;
}

.footerCenterStyle{
    font-size: 11px;
    margin-top: 120px;
    margin-bottom: 32px;
}

.footerRightStyle{
    width: 171px;
    margin-top: 35px;
    margin-right: 50px;
    float: right;
    text-align: center;
}

@media (max-width: 576px){
    .footerWrapperStyle{
        height: 260px;
        margin-top: 114px;
    }

    .footerStyle{
        height: 260px;        
    }

    .footerLeftStyle{
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        float: none;
    }

    .footerCenterStyle{
        font-size: 11px;
        margin-top: 10px;
        margin-bottom: 10px;
    }    

    .footerRightStyle{
        width: 100%;
        margin-top: 15px;
        margin-right: 50px;
        float: none;
        text-align: center;
    }
}

@media (max-width: 875px){
    .mt-5, .my-5{
        margin-top: 7rem !important;
    }
}

.layoutContainer{

}

.compLogo{

}

@media (max-width: 465px){
    .layoutContainer{
        padding-top: 20px;
    }

    .compLogo{
        margin-top: 20px;
    }
}

.bootstrapTableHeaderStyle{
    font-size: 14px;
    background-color: #FFFFFF;
}

.search-label{
    width: 100% !important;
}

.copy-skill-checkbox .custom-control-label {
    padding-top: 0px !important;
}

.sc-eDWCr{
    white-space: normal !important;
    text-overflow: clip !important;
}

.tooltip-inner{
    max-width: 500px !important;
}

.fullscreen-modal{
    width: 100% !important;
    height: 100%;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

.modal-full{
    height: 100%;
    max-width: 100% !important;
    margin: 0px !important;
}

.powerbi-report-style-class{
    height: 100%;
}

.futureRequirementCheckLink{
    text-decoration: "underline";
    color: "#007bff";
}

.chatboticon{
    height: "100px"; 
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 50% 50%;
    position: fixed;
    z-index: 10;
    right: 15px;
    bottom: 15px;
    cursor: pointer;
}

.col-md-0-5,
.col-md-1-5,
.col-md-10-5,
.col-md-11-5,
.col-md-2-5,
.col-md-3-5,
.col-md-4-5,
.col-md-5-5,
.col-md-6-5,
.col-md-7-5,
.col-md-8-5,
.col-md-9-5 {
    float: left;
    padding-right: 15px;
    padding-left: 15px;
}

.col-md-11-5 {
    width: 95.833333%
}
.col-md-10-5 {
    width: 87.5%
}
.col-md-9-5 {
    width: 79.1666667%
}
.col-md-8-5 {
    width: 70.8333333%
}
.col-md-7-5 {
    width: 62.5%
}
.col-md-6-5 {
    width: 54.1666667%
}
.col-md-5-5 {
    width: 45.8333333%
}
.col-md-4-5 {
    width: 37.5%
}
.col-md-3-5 {
    width: 29.166667%
}
.col-md-2-5 {
    width: 20.833333333%
}
.col-md-1-5 {
    width: 12.5%
}
.col-md-0-5 {
    width: 4.166667%
}

.ant-pagination-options{
    display: none !important;
}

.react-datepicker-popper{
    z-index: 1000 !important;
}