.edit-action-checkbox {
    padding-top: 10px;
    width: 120px;
}

.edit-action-checkbox .custom-control-label {
    padding-top: 0px;
    font-size: 14px;
}

.edit-propose-action-checkbox {
    padding-top: 10px;
    width: 260px;
}

.edit-propose-action-checkbox .custom-control-label {
    padding-top: 0px;
    font-size: 14px;
}

.btn-slim{
    padding: 1px !important;
}

.edit-action-260-checkbox {
    padding-top: 10px;
    width: 260px;
}

.edit-action-260-checkbox .custom-control-label {
    padding-top: 0px;
    font-size: 14px;
}