.custom-control-label {
    padding-top: 3px !important;
    margin-top: 0px;
}

.custom-100 {
    width: 100px;
}

.custom-160 {
    width: 160px;
}

.custom-200 {
    width: 200px;
}

.custom-250 {
    width: 250px;
}

.custom-300 {
    width: 300px;
}

.MuiInputLabel-outlined {
    margin-top: -10px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px !important;
}

.rw-input-addon.rw-picker-btn {
    border-left: none;
}
